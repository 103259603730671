<template>
  <div>
    <el-card :body-style="{ padding: '25px' }">
      <div slot="header">
        <div style="margin-left: 0px">
          <div class="buscador3">
            <maca-input-delay
              v-model="filtroNombre"
              icon="el-icon-search"
              placeholder="Buscar por nombre"
            ></maca-input-delay>
          </div>
        </div>
        <el-button
          class="botonmasIcono"
          icon="el-icon-plus"
          type="primary"
          round
          style="margin-right: 0px"
          @click="$refs.modalNuevo.abrir()"
          >Nuevo</el-button
        >
      </div>

      <div>
        <maca-datatable
          :url="urlTabla"
          :params="paramsTabla"
          :actualizar.sync="actualizarTabla"
          :bloquear.sync="bloquearTabla"
        >
          <el-table-column :min-width="120" label="Nombre">
            <template slot-scope="props">
              <span
                v-text="
                  props.row.persona.nombre + ' ' + props.row.persona.apellido
                "
              ></span>
            </template>
          </el-table-column>

          <el-table-column label="Username" prop="username"></el-table-column>

          <el-table-column label="Inhabilitado" width="105" align="center">
            <template slot-scope="props">
              <el-switch
                v-model="props.row.bloqueado"
                :active-value="1"
                :inactive-value="0"
                @change="toogleBloqueado(props.row)"
                :disabled="
                  props.row.bloqueado
                    ? !$store.getters.tienePermiso('M_USE_DES')
                    : !$store.getters.tienePermiso('M_USE_BLO')
                "
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="Editar" width="70">
            <template slot-scope="props">
              <el-button
                type="primary"
                circle
                @click="$refs.modalModificar.abrir(props.row.id)"
                :disabled="!$store.getters.tienePermiso('M_USE')"
              >
                <i class="el-icon-edit"></i>
              </el-button>
            </template>
          </el-table-column>
          <el-table-column label="Borrar" width="70">
            <template slot-scope="props">
              <el-button
                type="danger"
                @click="eliminar(props.row.id)"
                circle
                :disabled="!$store.getters.tienePermiso('B_USE')"
              >
                <i class="el-icon-delete"></i>
              </el-button>
            </template>
          </el-table-column>
        </maca-datatable>
      </div>
    </el-card>
    <modal-nuevo
      ref="modalNuevo"
      @actualizar-tabla="actualizarTabla = true"
    ></modal-nuevo>
    <modal-modificar
      ref="modalModificar"
      @actualizar-tabla="actualizarTabla = true"
    ></modal-modificar>
  </div>
</template>

<script>
import ModalNuevo from "./modales/nuevo";
import ModalModificar from "./modales/modificar";

export default {
  name: "usuario",
  components: {
    ModalNuevo,
    ModalModificar,
  },
  data() {
    return {
      urlTabla: "/user/obtenerTodos",
      paramsTabla: { rolID: 5 },
      actualizarTabla: true,
      bloquearTabla: true,

      filtroNombre: null,
    };
  },
  methods: {
    async eliminar(id) {
      let confirmado = await this.$confirm(
        "Confirme que desea eliminar el usuario."
      );

      if (!confirmado) {
        return;
      }

      this.bloquearTabla = true;

      // Hacer post
      let params = { id: id };

      let respuestaApi = await this.$maca.api.post("/user/eliminar", params);
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Usuario borrado con éxito!",
          type: "success",
        });
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
    async toogleBloqueado(fila) {
      this.bloquearTabla = true;

      // Hacer post
      let params = { id: fila.id };
      let respuestaApi = null;

      if (fila.bloqueado) {
        respuestaApi = await this.$maca.api.post("/user/bloquear", params);
      } else {
        respuestaApi = await this.$maca.api.post("/user/desbloquear", params);
      }
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado != 1) {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
  },
  watch: {
    filtroNombre() {
      this.paramsTabla = { nombre: this.filtroNombre, rolID: 5 };
      this.actualizarTabla = true;
    },
  },
};
</script>
