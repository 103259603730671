<template>
  <div>
    <maca-modal titulo="Nuevo técnico" :impedirClose="impedirClose" ref="modal">
      <el-form
        :model="form"
        ref="form"
        :rules="formRules"
        label-position="left"
        label-width="130px"
      >
        <!-- <el-form-item label="Buscar Persona" prop="persona">
          <div style="display: flex">
            <maca-select-box-persona
              v-model="persona"
            ></maca-select-box-persona>
          </div>
        </el-form-item> -->
        <el-form-item label="Nombre" prop="nombre">
          <el-input v-model="form.nombre"></el-input>
        </el-form-item>
        <el-form-item label="Apellido" prop="apellido">
          <el-input v-model="form.apellido"></el-input>
        </el-form-item>
        <el-form-item label="DNI" prop="dni">
          <el-input v-model="form.dni"></el-input>
        </el-form-item>
        <el-form-item label="Nombre de Usuario" prop="username">
          <el-input v-model="form.username"></el-input>
        </el-form-item>
        <el-form-item label="Contraseña" prop="password">
          <el-input v-model="form.password"></el-input>
        </el-form-item>
        <el-form-item>
          <maca-boton-guardar :onSubmit="onSubmit"></maca-boton-guardar>
        </el-form-item>
      </el-form>
    </maca-modal>
  </div>
</template>

<script>
export default {
  name: "nuevo-usuario",
  data() {
    return {
      form: {
        nombre: null,
        apellido: null,
        username: null,
        password: null,
        dni: null,
      },
      impedirClose: false,
      persona: null,

      urlRol: "/user/obtenerTodosSelectRoles",

      formRules: {
        nombre: [
          {
            required: true,
            message: "Por favor introduzca el nombre.",
            trigger: "change",
          },
        ],
        apellido: [
          {
            required: true,
            message: "Por favor introduzca el apellido.",
            trigger: "change",
          },
        ],
        dni: [
          {
            required: true,
            message: "Por favor introduzca el DNI.",
            trigger: "change",
          },
        ],
        username: [
          {
            required: true,
            message: "Por favor introduzca el nombre de usuario.",
            trigger: "change",
          },
        ],
        password: [
          {
            required: true,
            message: "Por favor introduzca la contraseña de usuario.",
            trigger: "change",
          },
        ],
        rol: [
          {
            required: true,
            message: "Por favor introduzca el rol.",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    abrir() {
      this.$refs.modal.abrir();

      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }

      this.persona = null;
    },
    cerrar() {
      this.$refs.modal.cerrar();
    },
    onSubmit(afterSubmit) {
      this.$refs.form.validate(async (formOk, _) => {
        if (!formOk) {
          afterSubmit();
          return false;
        }

        this.impedirClose = true;

        let postOk = await this.postApi();

        this.impedirClose = false;
        afterSubmit();

        if (postOk) {
          this.$emit(this.$maca.eventStrings._actualizarTabla);
          this.cerrar();
        }
      });
    },
    async postApi() {
      // Hacer Post
      let params = {
        username: this.form.username,
        password: this.form.password,
        nombre: this.form.nombre,
        apellido: this.form.apellido,
        personaID: this.form.personaID,
        rolID: 5,
        dni: this.form.dni,
      };

      let respuestaApi = await this.$maca.api.post("/user/crear", params);
      //

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Técnico creado con éxito!",
          type: "success",
        });

        return true;
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //

      return false;
    },
  },
  /* watch: {
    persona() {
      if (this.persona == null) {
        this.form.personaID = null;
        return;
      }

      this.form.nombre = this.persona.nombre;
      this.form.apellido = this.persona.apellido;
      this.form.password = this.persona.dni;
    },
  }, */
};
</script>
